@use "@angular/material" as mat;

@import "./variables";

$font-size-base: 0.875rem;
@import "../node_modules/bootstrap/scss/bootstrap";

@include mat.core();
@include mat.all-component-themes($app-theme);
@include mat.typography-hierarchy($app-typography);

@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/materialicons.woff2) format("woff2");
}

.material-icons {
  font-family: "Material Icons", 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  // -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;

  .mat-badge-content {
    font-family: 'Noto Sans SC';
  }
}

html,
body {
  height: 100%;
  background-color: $app-background-color;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mdc-tab {
  min-width: 0 !important;
  padding-left: 0px !important;
  padding-right: 1.5rem !important;
}

.mdc-tab__content {
  height: 31px !important;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 31px !important;
}

button.mdc-icon-button {
  display: flex;
}

button.btn-sm {
  width: 36px !important;
  height: 36px !important;
  padding: 6px !important;

  & .mat-mdc-button-touch-target {
    width: 36px !important;
    height: 36px !important;
  }
}

button.active {
  background-color: rgba(63, 81, 181, 0.15) !important;
}

button.btn-md {
  & img {
    width: 24px !important;
    height: 24px !important;
    margin: 0 0.25rem 0 0 !important;
  }
}

button.btn-lg {
  font-size: large !important;
  height: 48px !important;

  & img {
    width: 24px !important;
    height: 24px !important;
    margin: 0 0.5rem 0 0 !important;
  }
}

mat-form-field {
  width: 100%;
  margin-bottom: 0.5rem;
}

mat-card {
  padding: 0.5rem;
  // display: flex;
  // flex-direction: column;
  box-shadow: none !important;
}

.text-primary {
  color: $app-primary-color !important;
}

.text-accent {
  color: $app-accent-color !important;
}

.text-warn {
  color: $app-warn-color !important;
}

.text-orange {
  color: $app-orange-color !important;
}

.text-gold {
  color: #FFCC7F !important;
}

.text-white {
  color: white;
}

.h-0 {
  height: 0% !important;
}

.min-h-100 {
  min-height: 100%;
}

.max-h-100 {
  max-height: 100%;
}

.ratio-3x4 {
  aspect-ratio: 125%;
  --bs-aspect-ratio: 125%;
}

.progress-spinner .mat-mdc-dialog-surface {
  background-color: transparent !important;
  box-shadow: none !important;
}

.btn-min-width {
  min-width: 5.5rem !important;
}

.message-snackbar {
  &>div {
    background: mat.get-color-from-palette($app-primary) !important;
    color: $white !important;
  }

  &.warn {
    &>div {
      background: $yellow !important;
    }
  }

  &.error {
    &>div {
      background: mat.get-color-from-palette($app-warn) !important;
    }
  }
}

.selected {
  box-shadow: 0 0 0 0.125rem $app-primary-color;
}

.transform-flip-y {
  -webkit-transform: scaleY(-1);
  transform: scaleY(-1);
}

.cursor-rotate {
  cursor: url('./assets/images/rotate.png'), auto;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-lg {
  width: 36px;
  height: 36px;
}

.icon-md {
  width: 30px;
  height: 30px;
}

.icon-sm {
  width: 24px;
  height: 24px;
}

.icon-xs {
  width: 18px !important;
  height: 18px !important;
  font-size: 18px !important;
}

.top-left {
  position: absolute !important;
  top: 0;
  left: 0;
}

.top-right {
  position: absolute !important;
  top: 0;
  right: 0;
}

.bottom-left {
  position: absolute !important;
  bottom: 0;
  left: 0;
}

.bottom-right {
  position: absolute !important;
  bottom: 0;
  right: 0;
}

.center-center {
  position: absolute !important;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.fs-small {
  font-size: 0.875rem !important;
}

.fs-smaller {
  font-size: 0.75rem !important;
}

.fs-larger {
  font-size: 1rem !important;
}

.fs-large {
  font-size: 1.125rem !important;
}

.page-header {
  display: flex;
  align-items: center;
  min-height: $page-header-height;

  & .title {
    font-weight: 600;
    display: inline-block;
  }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.flex-1 {
  flex: 1;
}

mat-table mat-row:hover {
  background-color: $app-accent-color !important;
}

.background-alpha {
  // background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"><rect x="0" y="0" width="8" height="8" style="fill:rgb(153,153,153);"/><rect x="8" y="0" width="8" height="8" style="fill:rgb(102,102,102);"/><rect x="0" y="8" width="8" height="8" style="fill:rgb(102,102,102);"/><rect x="8" y="8" width="8" height="8" style="fill:rgb(153,153,153);"/></svg>');
  background: url('data:image/svg+xml;utf8,<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" width="16px" height="16px"><rect x="0" y="0" width="8" height="8" style="fill:rgb(224,224,224);"/><rect x="8" y="0" width="8" height="8" style="fill:rgb(189,189,189);"/><rect x="0" y="8" width="8" height="8" style="fill:rgb(189,189,189);"/><rect x="8" y="8" width="8" height="8" style="fill:rgb(224,224,224);"/></svg>');
  background-repeat: repeat;
}

.background-alpha-dark {
  background: url('data:image/svg+xml;utf8,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Crect%20y%3D%2210%22%20width%3D%2210%22%20height%3D%2210%22%20fill%3D%22%233B4253%22%2F%3E%3Crect%20x%3D%2210%22%20width%3D%2210%22%20height%3D%2210%22%20fill%3D%22%233B4253%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: repeat;
}

.background-lighter {
  background-color: $app-dialog-color;
}

.background-light {
  background-color: #262B37;
}

.background-primary {
  background-color: $app-primary-color;
}

.background-accent {
  background-color: #39404f;
}

.background-orange {
  background-color: orange;
}

.background-gold {
  background-color: #FFCC7F;
}

.background-white {
  background-color: white;
}

.image-area {
  border-radius: 0.3rem;

  img {
    border-radius: 0.3rem;
  }
}

.template-image {
  img {
    object-fit: cover;
  }
}

.border-lighter {
  border: 1px solid #323847;
}

.app-icon-btn {
  background-color: black;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem;
  display: flex;
  align-items: center;
}

.text-area {
  resize: none;
  color: white;
  border-radius: 0.3rem;
  border: 1px solid #323847;
  padding: 0.25rem 0.5rem;
}

.text-area:focus {
  outline: 0;
}

.hint-text {
  color: #FFFFFF99;
}

.app-button {
  height: 32px !important;

  &.mat-mdc-button,
  &.mat-mdc-raised-button,
  &.mdc-button--unelevated,
  &.mdc-button--outlined {
    border: 1px solid $app-primary-color;
  }

  span {
    font-family: 'Noto Sans SC';
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
  }
}

.button-text {
  font-family: 'Noto Sans SC';
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
}

.task-header {
  height: 54px;
}

.color-picker-ok-button {
  background-color: $app-primary-color;
  border: none;
  border-radius: 0.25rem;
  font-weight: 600;
}

.color-picker-cancel-button {
  border: none;
  border-radius: 0.25rem;
  margin: auto 0.5rem !important;
}

.max-width-xs {
  max-width: 400px;
}

.max-width-sm {
  max-width: 576px;
}

.max-width-md {
  max-width: 768px;
}

.max-width-lg {
  max-width: 992px;
}

.max-width-xl {
  max-width: 1200px;
}

.max-width-xxl {
  max-width: 1400px;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #1F232D;
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-label-text-color: #1F232D;
}

.mat-mdc-unelevated-button.mat-mdc-button-disabled {
  --mdc-filled-button-disabled-label-text-color: #285355;
  --mdc-filled-button-disabled-container-color: #359a92;
}

.user-menu {
  background-color: #272D3A !important;
  color: white;
  margin: 1rem;
  padding: 0.5rem 0.75rem;
  min-width: 240px !important;
  max-width: 300px !important;
}

@media (min-width: 992px) {
  image-segment-picker img {
    max-height: calc(100vh - 300px);
  }

  .h-lg-100 {
    height: 100%;
  }
}

// Apply the light theme only when the user prefers light themes.
@media (prefers-color-scheme: light) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  // TODO：是否支持light-theme，有待以后讨论
  // @include mat.all-component-colors($light-theme);
}