@use 'sass:map';
@use "@angular/material" as mat;

$app-primary: mat.define-palette(map.deep-merge(mat.$cyan-palette,
      (500: #4df3df,
      ), ), );
$app-accent: mat.define-palette(map.deep-merge(mat.$grey-palette,
      (500: #999999,
      ), ), );
$app-warn: mat.define-palette(mat.$red-palette);
$app-typography: mat.define-typography-config(
  $headline-1: mat.define-typography-level(112px, 112px, 300, 'Noto Sans SC', -0.0134em),
  $headline-2: mat.define-typography-level(56px, 56px, 400, 'Noto Sans SC', -0.0089em),
  $headline-3: mat.define-typography-level(45px, 48px, 400, 'Noto Sans SC', 0.0000em),
  $headline-4: mat.define-typography-level(34px, 40px, 400, 'Noto Sans SC', 0.0074em),
  $headline-5: mat.define-typography-level(24px, 32px, 400, 'Noto Sans SC', 0.0000em),
  $headline-6: mat.define-typography-level(20px, 32px, 600, 'Noto Sans SC', 0.0075em),
  $subtitle-1: mat.define-typography-level(16px, 22px, 600, 'Noto Sans SC', 0.0094em),
  $subtitle-2: mat.define-typography-level(14px, 22px, 600, 'Noto Sans SC', 0.0071em),
  $body-2: mat.define-typography-level(14px, 22px, 400, 'Noto Sans SC', 0.0179em),
  $body-1: mat.define-typography-level(16px, 22px, 400, 'Noto Sans SC', 0.0179em),
  $button: mat.define-typography-level(14px, 20px, 600, 'Noto Sans SC', 0.0893em),
  $caption: mat.define-typography-level(12px, 18px, 400, 'Noto Sans SC', 0.0333em),
);

$app-custom-theme: (
  color: (  background: (background: #11151E,
                         card: #191D26,
                          dialog: #1F232D,
       ),
     //foreground: (text: var(--text-primary),
     //),
  ),
);

// Define a dark theme
$app-theme: map.deep-merge(mat.define-dark-theme((color: (primary: $app-primary,
          accent: $app-accent,
          warn: $app-warn,
        ),
        density: 0,
        typography: $app-typography,
      ),
    ), $app-custom-theme);


$app-background-color: mat.get-theme-color($app-theme, background, background);
$app-card-color: mat.get-theme-color($app-theme, background, card);
$app-dialog-color: mat.get-theme-color($app-theme, background, dialog);
$app-primary-color: mat.get-color-from-palette($app-primary);
$app-accent-color: mat.get-color-from-palette($app-accent);
$app-warn-color: mat.get-color-from-palette($app-warn);
$app-orange-color: #FB983E; 

// $light-primary-white-color: #f3f5ff;
$yellow: #ffc260;
$pink: #ff4081;
$violet: #9013fe;
$white: white;
$dark-grey: #4a4a4a;
$light-grey: #b9b9b9;
$grey: #666666;
$gray-08: rgba(110, 110, 110, 0.8);
$gray-80: #d8d8d880;
$gray-06: rgba(110, 110, 110, 0.6);
$black-08: rgba(0, 0, 0, 0.08);

$page-header-height: 54px;
$page-footer-height: 48px;